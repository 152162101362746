/**
 * src/js/main.js
 *
 * main javascript file
 */

import MainNav from '/dist/js/modules/main-nav.js';
import Slider from '/dist/js/modules/slider.js';
import CTA from '/dist/js/modules/cta.js';
import Overlay from '/dist/js/modules/overlay.js';
import Dropdown from '/dist/js/modules/dropdown.js';
import LightBox from '/dist/js/modules/light_box.js';
import Map from '/dist/js/modules/map.js';
import Tenant from '/dist/js/modules/tenant.js';
import Filter from '/dist/js/modules/filter.js';
import Search from '/dist/js/modules/search.js';
import Message from '/dist/js/modules/message.js';
import Download from '/dist/js/modules/download.js';
import Team from '/dist/js/modules/team.js';


class MAIN {
	
	constructor() {
		this.debug = false;
		this.MainNav = new MainNav(this);
		this.Overlay = new Overlay(this);
		this.Dropdown = new Dropdown(this);
		document.addEventListener('DOMContentLoaded', () => this.setup());
	}
	
	setup() {
		if (this.debug) {
			console.log('APP::init');
		}
		
		this.CTA = new CTA(this);
		this.Slider = new Slider(this);
		this.LightBox = new LightBox(this, '.slide--fake');
		this.Tenant = new Tenant(this);
		this.Map = new Map(this);
		this.Filter = new Filter(this);
		this.Search = new Search(this);
		this.Message = new Message(this);
		this.Download = new Download(this);
		this.Team = new Team(this);
		
		this.pageInit();
	}

	pageInit() {
		if (this.debug) {
			console.log('APP::pageInit');
		}

		document.body.classList.add('page-has-loaded');
		this.main();
		this.Overlay.addOverlay("[name=openSearch]", ".search-overlay");
		this.Overlay.addOverlay("[name=openLogin]", ".login-overlay");
		this.Overlay.addOverlay(".login-overlay__forgot-password", ".message-overlay");
	}

	main() {
		// this.initThrottleResize();
		
		this.windowHeight = window.innerHeight;
		document.body.style.setProperty("--vh", document.documentElement.clientHeight / 100 + "px");
		
		window.addEventListener('resize', () => {
			this.windowHeight = window.innerHeight;
			document.body.style.setProperty("--vh", document.documentElement.clientHeight / 100 + "px");
		});
		
		if (window.pageYOffset > this.windowHeight) {
			document.body.classList.add('is-scrolled');
		}

		if (window.pageYOffset > this.windowHeight * 0.5) {
			document.body.classList.add('scolled-screen-height');
		}
		
		window.addEventListener('scroll', () => {
			if (window.pageYOffset > this.windowHeight * 0.5 - 1) {
				document.body.classList.add('scolled-screen-height');
			}
			
			if (window.pageYOffset < this.windowHeight * 0.5 - 1) {
				document.body.classList.remove('scolled-screen-height');
			}
		}, {passive: true});
	}
	
	closeOnOutsideClick(selector, callback) {
		this.element = typeof selector == 'string' ? document.querySelector(selector) : selector;
		document.addEventListener('click',(event) => {
			if (event.target.parentNode.closest(selector) == null) {
				callback(event.target);
			}
		});
	}
}


var app = new MAIN();